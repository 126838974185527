.container {
    width: 700px !important;
    margin: auto;
}

.radio {
    float: left;
}

.field {
    padding: 3px;
}

input[type='text'],
input[type='number']
{
    height: 35px !important
}

select {
    height: 41px !important
}

input[type='date'] {
    height: 37px !important;
    font-size:15px;
    font-family: 'arial'
}




/*textarea {
    height: 180px !important
}*/


.radio {
    float:left;
    padding: 0 0 0 0;
    align-items: center;
    display: flex;
}


.radio label {
   margin: 10px;
}

button {
    height: 41px !important
}

.css_separatorbar {
    background-color: #efefef;
    margin-top: 30px;
    padding:5px 5px 13px 5px !important; 
    border-radius: 8px;
}

.css_separatorbar small {
    font-size: 17px !important;
}


.css_checkbox {
    display: flex; 
    flex-direction: row-reverse;
    align-items: center;
    justify-content: left;
}

.css_checkbox label
{
    margin-left: 10px;
}


